<ng-container>
  <div class="message-wrapper">
    <div class="search-container-email" *ngIf="emailDetails">
      <span class="search-container-email__header">Email Message </span>
      <div class="email-box">
        <div class="email-header" *ngIf="isFileExist">
          {{fileNumber}} | {{subjectEmail}} {{receivedDate | date:'dd/MM/yyyy'}} at {{ receivedDate | date:'h:mm
          a'}}
        </div>
        <div class="email-header" *ngIf="!isFileExist">
          {{subjectEmail}} {{receivedDate | date:'dd/MM/yyyy'}} at {{ receivedDate | date:'h:mm
          a'}}
          <!-- <span class="close-button">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.17289 0.172798C0.227526 0.118023 0.292431 0.074566 0.363889 0.0449145C0.435346 0.0152629 0.511951 0 0.589316 0C0.666682 0 0.743287 0.0152629 0.814744 0.0449145C0.886201 0.074566 0.951107 0.118023 1.00574 0.172798L10.0001 9.16832L18.9944 0.172798C19.0919 0.0755433 19.2204 0.0155654 19.3575 0.00331283C19.4947 -0.00893975 19.6318 0.0273097 19.7449 0.105746L19.8273 0.172798C19.8821 0.227434 19.9255 0.29234 19.9552 0.363797C19.9848 0.435255 20.0001 0.51186 20.0001 0.589225C20.0001 0.66659 19.9848 0.743195 19.9552 0.814653C19.9255 0.88611 19.8821 0.951015 19.8273 1.00565L10.8318 10L19.8273 18.9943C19.9245 19.0918 19.9845 19.2203 19.9968 19.3575C20.009 19.4946 19.9728 19.6317 19.8943 19.7449L19.8273 19.8272C19.7727 19.882 19.7078 19.9254 19.6363 19.9551C19.5648 19.9847 19.4882 20 19.4109 20C19.3335 20 19.2569 19.9847 19.1854 19.9551C19.114 19.9254 19.0491 19.882 18.9944 19.8272L10.0001 10.8317L1.00574 19.8272C0.908276 19.9245 0.779778 19.9844 0.642635 19.9967C0.505492 20.0089 0.368398 19.9727 0.255234 19.8943L0.17289 19.8272C0.118115 19.7726 0.0746575 19.7077 0.045006 19.6362C0.0153545 19.5647 9.15527e-05 19.4881 9.15527e-05 19.4108C9.15527e-05 19.3334 0.0153545 19.2568 0.045006 19.1853C0.0746575 19.1139 0.118115 19.049 0.17289 18.9943L9.16841 10L0.17289 1.00565C0.0756348 0.908185 0.015657 0.779687 0.00340438 0.642544C-0.00884819 0.505401 0.0274013 0.368306 0.105838 0.255142L0.17289 0.172798Z"
                fill="#043B7C" />
            </svg>
          </span> -->
        </div>
        <div class="email-sub-box">
          <span class="email-sub-text">From: {{fromEmail}}</span><br>
          <span class="email-sub-text">To: {{toEmail}}</span><br>
          <span class="email-sub-text">Subject: {{subjectEmail}}</span><br>
          <span class="email-sub-text">Received: {{receivedDate | convertTime: 'utc' : timezone.value}}</span><br>
        </div>

        <div class="email-content" [innerHTML]="contentEmail |safeHtml">

        </div>
        <div class="footerlayout" *ngIf="emailDetails.attachments.length>0">
          <div class="doc-name" *ngFor="let x of emailDetails.attachments" (click)="previewAttachment(x)">
            <div class="docnameList">
              <div class="icon">
                <lib-icon *ngIf="x.contentType!==''"
                  [name]="'file_'+ x.name.split('.')[x.name.split('.').length - 1].toLowerCase()">
                </lib-icon>
                <lib-icon name="mail" *ngIf="x.contentType==''">
                </lib-icon>
              </div>
              <div class="name">
                <p>{{x.name}}</p>
                <p class="ptext">{{formatBytes(x.size)}}</p>
              </div>
            </div>

            <div class="icon preview zoomicon">
              <lib-icon name="magnifying_glass_plus">
              </lib-icon>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>